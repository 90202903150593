.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.App .logo {
  font-family: 'Lalezar', cursive;
  font-size: 25px;
  text-decoration: none;
  color: #1bb746;
  position: relative;
  z-index: 0;
  margin: 0px;
  /* display: block; */
  float: left;
  left: -5px;
  top: 10px;
  bottom: 0px;
  padding: 0px 5px;
  background-color: transparent;
}

.App .footer {
  text-align: center;
  /* margin-top: 20px; */
  padding-top: 30px;
  padding-bottom: 80px;
  border-top: 1px solid #f2f2f2;
  /* background-color: #fff; */
  font-weight: 400;
  font-size: 14px;
  max-width: 1130px;
  margin: -1px auto 0px auto;
}

.App .footer .footer-right-section {
  float: right;
}

.App .footer .title {
  border-bottom: 1px solid #ddd;
  padding: 15px 0px 5px 0px;
  text-align: left;
}

.App .footer .description {
  color: #999;
  padding: 15px 0px 5px 7px;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
}

.App .footer .logo {
  font-family: 'Lalezar', cursive;
  font-size: 19px;
  text-decoration: none;
  color: #02c39a;
  padding: 0px 20px 0px 20px;
  position: inherit;
  /* border-right: 1px solid #e0e0e0; */
  margin-right: -10px;
  top: 0px;
}

.App .footer .menu {
  font-size: 13px;
  font-weight: 600;
  margin-top: 0px;
  margin-right: 0px;
  float: right;
}

.App .footer .menu a {
  margin-left: 10px;
  padding: 10px;
  text-decoration: none;
  color: #828282;
  vertical-align: middle;
}

.App .footer .separator .material-icons {
  color: #02c39a;
  font-size: 6px;
  margin-right: -8px;
  margin-left: 3px;
}


@media (max-width: 599px) {
  .App .footer {
    padding-bottom: 20px;
  }

  .App .footer .logo {
    padding: 3px 20px 0px 20px;
    float: none;
    margin: 0px auto;
    text-align: center;
    display: block;
  }

  .App .footer .menu {
    float: left;
    padding-top: 10px;
    margin-top: 0px;
    width: 100%;
    text-align: center;
  }

  .App .footer .menu .instagram {
    clear: both;
    display: block;
  }

  .main-title {
    padding: 0px;
    font-size: 19px !important;
    /* max-width: 320px; */
    margin: -10px auto 0px auto;
    display: block;
    /* font-size: 1.6rem !important; */
  }
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.app-container {
  /* margin-top: 60px; */
  min-height: 100vh;
}

.expansion_panel {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.expansion_panel:hover {
  background-color: #f9f9f9;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.expansion_panel[aria-expanded="true"]:hover {
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.main-nav {
  /*background-color: #fff;*/
  /*border-top: 1px solid #eee;*/
  /*margin-top: 10px;*/
  top: 0px;
  border-bottom: 1px solid #ddd;
  margin: -12px 0px -10px 0px;
  padding: 10px 0px 20px 0px;
  width: 100%;
  z-index: 0;
  text-align: left;
  /*box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 1px -1px rgba(0, 0, 0, 0.02);*/
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.main-nav a {
  text-decoration: none;
  padding: 10px 15px 10px 25px;
  /*width: 85%;*/
  display: inline-block;
  color: #00a1ff;
  font-weight: 500;
  font-size: 17px
}

.main-nav a:hover {
  color: #303f9f;
}

.main-nav .active > a {
  font-weight: 500;
  font-size: 17px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.main-nav .active a:hover {
  /*background-color: #fff;*/
  cursor: initial;
}

.main-nav a > small {
  color: #999;
  margin-top: 0px;
  display: block;
  font-size: 13px;
}

.main-nav span {
  padding: 0px;
  position: relative;
}

.main-nav .navigation-container span {
  min-width: 230px;
  display: block;
  /*border-left: 1px solid #eee;*/
  padding: 0px;
  float: left;
}

.main-nav .navigation-container span:last-child {
  min-width: 300px;
  display: block;
  padding: 0px;
  float: left;
  /*border-left: 1px solid #eee;*/
}

/* .main-nav .navigation-container span:first-child {
  border-left: 1px solid #fff;
} */

.main-nav span:not(:last-child)::after {
  position: absolute;
  z-index: 2;
  content: '';
  top: 50%;
  right: 2px;
  border: medium none;
  /*background-color: #fff;*/
  width: 1.14285714em;
  height: 1.14285714em;
  border-style: solid;
  border-color: #ccc;
  border-width: 0 1px 1px 0;
  /*-webkit-transition: background-color .1s ease,opacity .1s ease,color .1s ease,-webkit-box-shadow .1s ease;
  transition: background-color .1s ease,opacity .1s ease,color .1s ease,-webkit-box-shadow .1s ease;
  transition: background-color .1s ease,opacity .1s ease,color .1s ease,box-shadow .1s ease;
  transition: background-color .1s ease,opacity .1s ease,color .1s ease,box-shadow .1s ease,-webkit-box-shadow .1s ease;*/
  -webkit-transform: translateY(-50%) translateX(50%) rotate(-45deg);
  transform: translateY(-50%) translateX(50%) rotate(-45deg);
}

.icon-rotate {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.filters {
  text-align: center;
  /*margin-top: 10px;*/
  /*box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 1px -1px rgba(0, 0, 0, 0.02);*/
  background-color: #fff;
  padding: 7px;
  /*border-top: 1px solid #eee;*/
  border-bottom: 2px solid rgba(0, 0, 0, 0.09);
}

.explore-description {
  text-align: center;
  margin: auto;
  vertical-align: middle;
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  padding: 0px 20px;
}

.small-description {
  font-weight: 400;
  display: block;
  color: #777;
  margin-top: -5px;
  margin-bottom: 20px;
}

.mini-card {
  cursor: pointer;
  display: inline-block;
}

.App a.logo {
  font-family: 'Lalezar', cursive;
  font-size: 25px;
  text-decoration: none;
  color: #17ad41;
  position: relative;
  float: left;
  top: 5px;
  left: 0px;
  z-index: 0;
  display: block;
}

.home .search {
  padding: 10px;
  min-height: 70vh;
  width: 100%;
  background-color: #fff;
  max-width: calc(100vw - 20px);
  position: relative;
}

.home .search-input-container {
  margin-bottom: 10px;
}

.home .explore-container {
  padding: 30px;
  text-align: left;
  position: relative;
  border-top: 0px solid rgba(0, 0, 0, 0.09);
  background-color: #fafafa;
  width: 100%;
  max-width: calc(100vw - 60px);
}

.home .explore-description {
  text-align: center;
  margin: auto;
  vertical-align: middle;
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  padding: 0px 20px;
  position: relative;
}

.home .mini-card {
  padding: 12px 7px !important;
}

.home a.logo {
  font-family: 'Lalezar', cursive;
  font-size: 35px;
  text-decoration: none;
  color: #1bb746;
  position: relative;
  /* top: 42px; */
  left: 0px;
  z-index: 0;
  margin: 0px auto;
  display: block;
  width: -webkit-fill-available;
  margin-top: 10vh;
  padding-bottom: 50px;
}

.home a.logo small {
  display: block;
  text-align: right;
  font-size: 10px;
  font-weight: 600;
  margin-left: 4px;
  color: #38ad8d;
  text-shadow: none;
  letter-spacing: normal;
  padding-right: 6px;
}

@media all and (min-width:0px) and (max-width: 384px) {
  .home a.logo {
    margin-top: 10px;
  }
}

.destinations {
  margin-top: -40px;
}

.layout {
  background-color: #fafafa;
}

.bg-dark {
  background-color: #000 !important;
}

.no-min-height {
  min-height: auto !important;
}

.grid-filters {
  text-align: left;
  max-width: 1130;
  margin: 0px auto;
}

.destinations .destinations-container,
.layout .destinations-container  {
  padding: 10px 15px;
  text-align: left;
  min-height: calc(100vh - 155px);
  background-color: #fff;
  /* border-top: 1px solid #eee; */
}

.destinations .logo {
  font-family: 'Lalezar', cursive;
  font-size: 25px;
  text-decoration: none;
  color: #1bb746;
  position: relative;
  z-index: 0;
  margin: 0px;
  display: block;
  float: none;
  left: -5px;
  top: -4px;
  bottom: 0px;
  padding: 0px 5px;
  background-color: transparent;
}

.layout .logo {
  font-family: 'Lalezar', cursive;
  font-size: 25px;
  text-decoration: none;
  color: #1bb746;
  position: relative;
  z-index: 0;
  margin: 0px;
  /* display: block; */
  float: left;
  top: 10px;
  left: -5px;
  bottom: 0px;
  padding: 5px;
  background-color: transparent;
}

.destinations .search-container {
  /* max-width: 1160px;
  margin: 100px auto -6px auto; */
  /* background-image: url("https://images.unsplash.com/photo-1471189641895-16c58a695bcb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2101&q=80");
  background-size: cover;
  background-position: bottom; */
  background-color: #fff;
  height: 330px;
  min-height: 90vh;
  /* background-image: url(https://images.unsplash.com/photo-1589718781653-61094dc392f2?ixlib=rb-1.2.1&auto=format&fit=crop&w=2067&q=80); */
  background-position: center center;
  background-size: cover;
}
@media (max-width: 1195px) {
  .destinations .destinations-container,
  .layout .destinations-container  {
    padding: 10px 5px;
    text-align: left;
    min-height: calc(100vh - 155px);
    background-color: #fff;
    /* border-top: 1px solid #eee; */
  }
}

@media (max-width: 600px) {
  .destinations .search-container h2 {
    padding: 0px 15px 70px 15px;
    /* width: auto; */
  }
}

@media (max-width: 959px) {
  .destinations .search-container {
    height: inherit !important;
  }
}

@media (min-width: 600px) {
  .destinations .search-container h2 {
    padding: 0px 20px 70px 20px;
    /* width: auto; */
  }
}

.destinations .search-input-container,
.layout .search-input-container {
  position: relative;
  display: block;
  float: left;
  width: calc(100% - 70px);
  outline: none;
  background-color: #fff;
  text-align: left;
  border: 1px solid transparent;
  padding: 0px;
  margin: 0px 5px 0px 0px;
}

.destinations .search-input,
.destinations .search-input:focus,
.destinations .search-input:active,
.layout .search-input,
.layout .search-input:focus,
.layout .search-input:active {
  border: honeydew;
  display: block;
  padding: 17px;
  font-size: 16px;
  border-radius: 4px;
  outline: none;
  background-color: #fff;
  float: left;
  color: #333;
  max-width: inherit;
  min-width: calc(100% - 90px);
  height: 18px;
}

.destinations .search-bar-component,
.layout .search-bar-component {
  float: left;
}

.destinations .search-bar-container,
.layout .search-bar-container {
  padding: 0px;
  margin: 0px;
  top: 0px;
}

.destinations .explore-interests,
.layout .explore-interests {
  font-size: 14px;
  color: #777;
  display: block;
  width: 108px;
  height: 100px;
  float: left;
  position: absolute;
  padding: 15px;
  white-space: normal;
}

.destinations .mini-card,
.layout .mini-card {
  padding: 12px 0px 12px 10px !important;
}

.destinations .autocomplete-container,
.layout .autocomplete-container {
  border: none;
  /*box-shadow: 0 1px 1px rgba(0,0,0,0.1);*/
  border-radius: 0 0 2px 2px;
  position: relative;
  width: 100%;
  max-width: inherit;
  margin: 0px;
  padding-top: 55px;
  top: 0px;
  z-index: 30;
}

.destinations .searchbar,
.layout .searchbar {
  min-height: 49px;
  margin: 0px;
  max-width: inherit;
}

.destinations .search-input-container .icon {
  padding: 13px 0px 15px 11px;
  color: #02c39a;
  font-weight: 400;
}

.layout .search-container {
  max-width: 1130px;
  margin: 0px auto 0px auto;
  min-height: 59px;
  text-align: left;
  padding: 0px 10px;
}

.react-dropzone-s3-uploader {
  width: 80px !important;
  border: none !important;
  overflow: hidden !important;
  height: 70px !important;
  z-index: 10 !important;
  position: absolute !important;
  float: right !important;
  display: block !important;
  right: 0px !important;
  top: 3px !important;
}

.material-icons-outlined notranslate {
  width: 24px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  z-index: 10;
}

.trip-container {
  /* background-color: #fff;
  border-top: 1px solid #eee; */
  min-height: calc(100vh - 209px);
  padding: 20px 10px 10px 10px;
  margin: 0px auto;
  max-width: 1130px;
  text-align: left;
}

.trip-component {
  padding: 10px 0px 0px 0px;
  text-align: left;
}
/*
.trip-container .hotels-container {
  padding: 0px 0px 0px 0px;
} */

.trip-container .activities-container {
  padding: 0px;
  margin: auto;
  text-align: left;
  /* min-height: 237px; */
}

.trip-container .notice-container {
  margin: -2px;
  display: flex;
}

.trip-container .destination-container {
  padding: 0px;
}

.main-nav .trip-price {
  float: right;
  /*border-right: 1px solid #eee;*/
  padding: 10px 25px 12px 15px;
}

.main-nav .trip-price-label {
  float: left;
  padding-top: 1px;
  padding-right: 30px;
  color: #333;
  margin-top: 0px;
  display: block;
  font-weight: 500;
  text-align: right;
}

.main-nav .trip-price-label small {
  color: #999;
  margin-top: 0px;
  display: block;
  font-size: 13px;
}

.main-nav .trip-price-number {
  float: right;
  font-size: 23px;
  font-weight: 500;
  padding-top: 5px;
}

.trip-filters {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: -10px;
  margin-top: 15px;
}

.trip-summary-dates {
  font-weight: 600;
  font-size: 65%;
  color: rgba(0, 0, 0, 0.54);
  display: block;
  margin-top: -6px;
}

.flights-container {
  padding: 0px;
  text-align: left;
  /* min-height: 165px; */
  position: relative;
  margin-bottom: 0px;
}
/*
.flights-container::before {
  position: absolute;
  z-index: 1;
  left: 15px;
  width: 2px;
  content: "";
  top: 0px;
  height: 100%;
  background: rgb(208, 233, 251);
} */

.flights-container .media {
  height: 0;
  padding-top: 56.25%;
}

.flights-container .cards {
  flex-grow: 1;
}

.flights-container .airline-logo {
  vertical-align: middle;
  padding-right: 5px;
  padding-left: 5px;
}

.flights-container .flight-price {
  text-align: left;
  vertical-align: middle;
  display: block;
  padding: 10px 0px 7px 10px !important;
  border-top: 1px solid #f2f2f2;
}

.flights-container .material-icons {
  vertical-align: middle;
  padding: 0px 11px 5px 5px;
  color: #e0e0e0;
}

.flights-container .flight-details {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 13px;
}

.flights-container .flight-details .travel-time {
  padding: 5px 0px;
}

.flights-container .flight-details .layover-time {
  background-color: #efefef;
  padding: 7px 0px 11px 40px;
  /* border-top: 1px solid #ddd; */
  /* border-bottom: 1px solid #ddd; */
  margin: 15px 0px;
  border-radius: 25px;
}

.flights-container .flight-details .second-heading {
  color: rgba(0, 0, 0, 0.62);
}

.flights-container .flight-details .directions {
  font-weight: 800;
  padding-left: 40px;
  padding-bottom: 20px;
}

.flights-container .flight-details .airline {
  float: right;
  margin-top: -5px;
}

.flights-container .flight-details .airline .second-heading {
  max-width: 75px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
}

.flights-container .flight-details .depart {
  border-right: 1px solid #eee;
  margin-bottom: 20px;
  margin-top: 10px;
  padding-right: 30px;
}

.flights-container .flight-details .return {
  padding-left: 20px;
  padding-right: 30px;
  margin-top: 10px;
}

.flights-container .selected {
  border: 2px solid #00a1ff;
}

@media (max-width: 599px) {
  .flights-container .flight-details .depart {
    padding-left: 20px;
    padding-right: 30px;
    margin-top: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    border-right: none;
  }
}

.hotels-container {
  padding: 0px 0px 0px 0px;
  position: relative;
  /*max-width: 1110px;*/
  margin: auto;
  text-align: left;
  /* min-height: 237px; */
}

.hotels-container .card {
  text-align: left;
  /* font-family: "Helvetica",Helvetica,Arial,sans-serif !important; */
}

.hotels-container h2 {
  font-size: 17px;
  /* font-family: "Helvetica",Helvetica,Arial,sans-serif !important; */
  overflow: hidden;
  margin-bottom: 7px;
  text-align: left;
  height: 22px;
  text-transform: lowercase;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 900;
}

.hotels-container h2::first-letter {
  text-transform: uppercase;
}

.hotels-container .accommodation-detail {
  text-align: left;
  color: #333;
}

.hotels-container .accommodation-rating {
  text-align: left;
  font-size: 13px;
  margin-top: 22px;
  color: #999;
  white-space: nowrap;
}

.hotels-container .accommodation-rating span {
  font-size: 13px;
  vertical-align: text-top;
  color: #333;
}

.hotels-container .bedroom-night {
  color: #999;
  display: inline-block;
  padding: 2px 0px 0px 0px;
}

.hotels-container .hotel-price {
  /* font-family: "Helvetica",Helvetica,Arial,sans-serif !important; */
  text-align: right;
  display: block;
  padding-top: 0px !important;
  font-size: 16px;
  font-weight: 800;
  margin-top: 2px;
}

.hotels-container .expansion_panel {
  overflow: hidden;
}

.hotels-container .selected {
  border: 2px solid #00a1ff;
}

.hotel-detail {
  white-space: pre-line;
  text-align: left;
  max-width: 100%;
  /* font-family: "Helvetica",Helvetica,Arial,sans-serif !important; */
}

.hotel-detail .amenities-category {
  color: #000;
  font-size: 15px;
  font-weight: 500;
}

.hotel-detail .amenities-category:not(:first-child) {
  margin-top: 25px;
  margin-bottom: 10px;
}

.hotel-detail .amenities-feature {
  width: 25%;
  display: inline-block;
  line-height: 25px;
}

.hotel-detail .review b {
  font-weight: 500;
  color: #000;
  font-size: 1.1em;
}

.hotel-detail .review .accommodation-rating {
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 2px;
}
