.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 15px;
}

.DayPicker_wrapper__horizontal {
  font-weight: 600;
  margin-left: -7px;
}

.DayPicker_calendarInfo__horizontal {
  width: 85vw;
  max-width: 305px;
}

.DayPicker_calendarInfo__horizontal > div {
  /* border-right: 1px solid #f5f5f5; */
  min-height: 170px;
  padding: 0px !important;
}

.DayPicker_calendarInfo__horizontal > div > li {
  padding: 10px 30px 10px 20px;
  font-size: 13px;
  border-radius: 25px;
  font-weight: 600;
}

.DayPicker_calendarInfo__horizontal > div > li.selected {
  background-color: #02c39a;
  color:#fff;
  border-radius: 25px;
  font-size: 13px;
  font-weight: 600;
}

.DayPicker_calendarInfo__horizontal > div > li.selected:hover {
  background-color: #02c39a;
  color:#fff;
  border-radius: 25px;
}

.calendar-disabled .DayPicker_wrapper__horizontal {
  opacity: 0;
  height: 0px;
  visibility: hidden !important;
}

.DayPicker__withBorder {
  padding: 10px;
  border: none;
  border-radius: 25px;
  box-shadow: none;
  width: 305px !important;
  visibility: visible !important;
}

.DayPickerNavigation_button__horizontalDefault {
  top: 14px !important;
  border-radius: 25px !important;
  padding: 9px 9px !important;
}