html {
  background-color: #fff;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  text-align: center;
  max-width: 100%;
  overflow-x: hidden;
}

body, #root, .page-wrapper {
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
  font-size: 15px;
  line-height: 1.42857143;
  color: #2c3e50;
  max-width: 100%;
  /* overflow-x: hidden; */
}

pre {
  text-align: left;
}


